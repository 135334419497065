import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import Logo from "@/assets/logo_renthunter.svg";
import { navigation } from "@/constants/index";
import useDevice from "@/hooks/useDevice";
import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { Mixpanel } from "@/services/mixpanel";

const NavItems = ({ setOpen, open }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const device = useDevice();
  const location = useLocation();
  const { user } = useSelector((state) => state.Auth);

  const logout = async () => {
    try {
      await api.post("/user/logout");
      api.setToken(null);
      dispatch(setUser(null));
      Mixpanel.track("profile_logout_btn");
      setOpen(false);
      if (location.pathname === "/") window.location.replace("/");
      return navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const AuthButtons = () => (
    <div className="flex flex-row  w-full  gap-1 justify-center items-center px-5 mt-5">
      <button onClick={() => navigate("/auth/signin")} className="w-2/4 h-12 btn-secondary">
        Login
      </button>
      <button onClick={() => navigate("/auth/signup")} className="w-2/4 h-12 btn-secondary">
        Sign up
      </button>
    </div>
  );

  const transitionClasses = device === "mobile" ? (open ? " translate-x-0" : "translate-x-full") : "";

  return (
    <>
      <div
        className={`fixed block overflow-y-auto transition-transform w-[270px] rounded-none md:rounded-lg z-[1000001] md:top-20 top-0 py-4 md:right-4 right-0 bg-white md:h-auto h-screen duration-400 ${transitionClasses}`}>
        <div className="flex px-8 md:hidden border-b pb-4 border-gray-100">
          <div
            className="flex  justify-center items-center cursor-pointer"
            onClick={() => {
              Mixpanel.track("sidebar_click_logo");
              navigate("/");
            }}>
            <img src={Logo} alt="logo" className="h-12" />
          </div>
          <button className=" text-2xl flex ml-auto items-center" onClick={() => setOpen((state) => !state)}>
            <IoMdClose />
          </button>
        </div>
        {user ? (
          <div>
            <div className="flex flex-col pt-4  w-full px-3.5  border-b pb-4  border-gray-100 justify-end">
              {navigation.map((item, index) => (
                <div key={index}>
                  <NavLink
                    key={item.name}
                    to={item.href}
                    exact={item.exact}
                    onClick={() => {
                      setOpen(false);
                      Mixpanel.track("sidebar_click_" + item.name.toLowerCase().replace(" ", "_"));
                    }}
                    className={({ isActive }) => {
                      const classes = "group flex justify-start items-center gap-x-3 p-3 text-primary-black-90 hover:bg-primary-100";
                      const activeClasses = "text-white bg-primary hover:bg-primary";
                      return isActive ? classes + " " + activeClasses : classes;
                    }}>
                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    <span className="flex flew-row gap-2 justify-start items-center">
                      {item.name}
                      {item.afterIcon && <item.afterIcon className="h-4 w-4 shrink-0 text-gray-800" aria-hidden="true" />}
                    </span>
                    {item.name == "Favorites" && user?.favorite_adverts?.length > 0 && (
                      <span className="bg-secondary w-6 h-6 text-white leading-none text-[14px] rounded-full text-center py-[5.5px]">{user.favorite_adverts.length}</span>
                    )}
                    {item.name == "Saved searches" && user?.saved_searches?.filter((search) => search.isActivated).length > 0 && (
                      <span className="bg-secondary w-6 h-6 text-white leading-none text-[14px] rounded-full text-center py-[5.5px]">
                        {user.saved_searches.filter((search) => search.isActivated).length}
                      </span>
                    )}
                  </NavLink>
                </div>
              ))}
            </div>
            <div className="pt-4 px-6 flex w-full justify-center items-center ">
              <button onClick={logout} className="btn-secondary w-full">
                Logout
              </button>
            </div>
          </div>
        ) : (
          <AuthButtons />
        )}
      </div>
    </>
  );
};

export default NavItems;
