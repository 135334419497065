import { PhoneNumberUtil } from "google-libphonenumber";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export function currencyToStr(currency) {
  if (!currency) return "";
  switch (currency) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    case "GBP":
      return "£";
    default:
      return currency;
  }
}

export function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function timeAgo(date) {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = Math.floor(seconds / (60 * 60 * 24 * 365));
  if (interval == 1) {
    return interval + " year";
  }
  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / (60 * 60 * 24 * 30));
  if (interval == 1) {
    return interval + " month";
  }
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / (60 * 60 * 24));
  if (interval == 1) {
    return interval + " day";
  }
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / (60 * 60));
  if (interval == 1) {
    return interval + " hour";
  }
  if (interval > 1) {
    return interval + " hours";
  }

  interval = Math.floor(seconds / 60);
  if (interval == 1) {
    return interval + " minute";
  }
  if (interval > 1) {
    return interval + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

const phoneUtil = PhoneNumberUtil.getInstance();
export function isPhoneValid(phone) {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
}

export function isEmail(email) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function validatePassword(password) {
  return password.length >= 6;
}
