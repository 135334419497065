import { useEffect } from "react";

function useOnClickOutside(refs, handler) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside any of the provided refs
      const isOutsideClick = refs.every((ref) => !ref.current || !ref.current.contains(event.target));

      if (isOutsideClick) handler(event);
    };

    // Add event listeners
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [refs, handler]);
}

export default useOnClickOutside;
