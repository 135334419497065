import React from "react";
import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

import useDevice from "@/hooks/useDevice";

export const Paginator = ({ page, setPage, last = 1 }) => {
  const pages = [...Array(last).keys()].map((i) => i + 1);

  const device = useDevice();
  const PAGINGATION_SIZE = device !== "mobile" ? 8 : 4;

  return (
    <div className="flex flex-row items-center justify-center gap-1">
      <button className="transparent-btn" onClick={() => setPage(1)} disabled={page === 1}>
        <BiFirstPage size="25px" />
      </button>
      <button className="mr-4 transparent-btn" onClick={() => (page - 1 < 1 ? setPage(1) : setPage(page - 1))} disabled={page === 1}>
        <MdNavigateBefore className="mr-1" size="25px" />
      </button>
      <div className={`w-372 flex items-center justify-center ${device !== "mobile" ? "gap-5" : "gap-3"}`}>
        {last > PAGINGATION_SIZE + 1 ? (
          page < PAGINGATION_SIZE ? (
            <>
              {pages.slice(0, PAGINGATION_SIZE).map((p) => (
                <button key={p} className={`transparent-btn ${p === page ? "bg-primary text-white py-1 px-2 rounded-sm" : ""}`} onClick={() => setPage(p)}>
                  {p}
                </button>
              ))}
              <button className="transparent-btn">...</button>
              <button className="transparent-btn" onClick={() => setPage(last)}>
                {last}
              </button>
            </>
          ) : page > last - (PAGINGATION_SIZE - 1) ? (
            <>
              <button className="transparent-btn" onClick={() => setPage(1)}>
                1
              </button>
              <button className="transparent-btn">...</button>
              {pages.slice(last - PAGINGATION_SIZE, last).map((p) => (
                <button key={p} className={`transparent-btn ${p === page ? "bg-primary text-white py-1 px-2 rounded-sm" : ""}`} onClick={() => setPage(p)}>
                  {p}
                </button>
              ))}
            </>
          ) : (
            <>
              <button className="transparent-btn" onClick={() => setPage(1)}>
                1
              </button>
              <button className="transparent-btn">...</button>
              {pages.slice(page - PAGINGATION_SIZE / 2, page + PAGINGATION_SIZE / 2 - 1).map((p) => (
                <button key={p} className={`transparent-btn ${p === page ? "bg-primary text-white py-1 px-2 rounded-sm" : ""}`} onClick={() => setPage(p)}>
                  {p}
                </button>
              ))}
              <button className="transparent-btn" disabled={true}>
                ...
              </button>
              <button className="transparent-btn" onClick={() => setPage(last)}>
                {last}
              </button>
            </>
          )
        ) : (
          pages.map((p) => (
            <button key={p} className={`transparent-btn ${p === page ? "bg-primary text-white py-1 px-2 rounded-sm" : ""}`} onClick={() => setPage(p)}>
              {p}
            </button>
          ))
        )}
      </div>
      <button className="ml-4 transparent-btn" onClick={() => (page + 1 > last ? setPage(last) : setPage(page + 1))} disabled={page === last}>
        <MdNavigateNext size="25px" className="" />
      </button>
      <button className="transparent-btn" onClick={() => setPage(last)} disabled={page === last}>
        <BiLastPage size="25px" />
      </button>
    </div>
  );
};
