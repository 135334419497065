import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaRegCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import Loader from "../../components/Loader";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { Select } from "../../components/Select";
import { Mixpanel } from "../../services/mixpanel";
import { isPhoneValid } from "../../utils";

const Home = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  const [values, setValues] = useState(user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!isPhoneValid(values.whatsapp_phone)) {
        return toast.error("Please enter a valid phone number");
      }

      const res = await api.put("/user", values);
      if (!res.ok) return toast.error("Profile update failed");
      toast.success("Profile updated successfully");
      dispatch(setUser(res.data));
      Mixpanel.track("profile_save_btn");
    } catch (error) {
      toast.error("Profile update failed");
      console.log(error);
    }
  };

  return (
    <div className="p-8">
      <div className="text-3xl mb-10">My Profile</div>
      {!values ? (
        <Loader />
      ) : (
        <>
          <form>
            <div className="flex flex-col gap-4 w-full lg:w-2/3">
              <div className="flex flex-wrap md:flex-nowrap gap-4">
                <div className="w-full">
                  <label className="text-xs text-gray-500">Firstname</label>
                  <input type="text" className="w-full" value={values?.firstname} onChange={(e) => setValues({ ...values, firstname: e.target.value })} />
                </div>
                <div className="w-full">
                  <label className="text-xs text-gray-500">Lastname</label>
                  <input type="text" className="w-full" value={values?.lastname} onChange={(e) => setValues({ ...values, lastname: e.target.value })} />
                </div>
              </div>
              <div className="flex flex-wrap md:flex-nowrap gap-4">
                <div className="w-full">
                  <label className="text-xs text-gray-500">Email</label>
                  <input type="text" disabled className="w-full" value={values?.email} />
                </div>
                <div className="w-full">
                  <label className="text-xs text-gray-500">Gender</label>
                  <Select options={["MALE", "FEMALE"]} value={values.gender || "MALE"} onChange={(e) => setValues({ ...values, gender: e })} width="w-full" nullable={false} />
                </div>
              </div>
              <div className="flex flex-wrap md:flex-nowrap gap-4">
                <div className="w-full md:w-1/2 md:pr-2">
                  <label className="text-xs text-gray-500 flex justify-start items-center">
                    Whatsapp phone number
                    {user.is_whatsapp_verified && <FaRegCheckCircle className="text-green-500 ml-1" />}
                  </label>
                  <PhoneNumberInput phone={values.whatsapp_phone} onChange={(e) => setValues({ ...values, whatsapp_phone: e })} />
                  <div className="text-sm text-gray-700 leading-none mt-1">
                    {user.is_whatsapp_undeliverable ? (
                      <>
                        <div className="text-red-500">Phone number is undeliverable</div>
                        <div className="mt-1">Please update your phone number</div>
                      </>
                    ) : (
                      !user.is_whatsapp_verified &&
                      user.whatsapp_phone && (
                        <>
                          <div>Phone in verification...</div>
                          <div className="mt-1">You should receive a welcome message on Whatsapp in the next few minutes</div>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="flex">
                <button onClick={handleSubmit} className="btn-primary w-full md:w-fit md:px-10">
                  Save
                </button>
              </div>
            </div>
          </form>

          <hr className="my-10" />

          {user.type == "PAID" ? (
            <>
              <div className="flex flex-col">
                <div className="text-xl">Manage my subscription</div>
                <button
                  onClick={() => {
                    Mixpanel.track("profile_click_customer_portal");
                    window.open("https://billing.stripe.com/p/login/5kAdTtfjD0Wf94kcMM", "_blank");
                  }}
                  className="btn-primary w-fit mt-5">
                  Manage my subscription
                </button>
              </div>
              <hr className="my-10" />
            </>
          ) : (
            <>
              <div className="flex flex-col">
                <div className="text-xl">You have no active subscription</div>
                <button
                  onClick={() => {
                    Mixpanel.track("profile_click_upgrade");
                    window.open("https://renthunter.nl/pricing", "_blank");
                  }}
                  className="btn-primary w-full md:w-fit mt-5">
                  Start your subscription
                </button>
              </div>
              <hr className="my-10" />
            </>
          )}

          <div className="flex flex-col">
            <div className="text-xl mb-3">Questions?</div>
            <span>
              For any questions or feedback, please email us at
              <span className="text-primary-700 hover:underline"> support@renthunter.nl </span>
              or use the chatbot :)
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
