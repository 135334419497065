import React, { useEffect, useRef, useState } from "react";
import Map, { Marker, NavigationControl, Popup, useMap } from "react-map-gl/maplibre";

import AdvertCardPopupMap from "./AdvertCardMapPopup";
import "./Map.css";

// from https://github.com/openmaptiles/maptiler-basic-gl-style/blob/master/style.json
const style = {
  version: 8,
  name: "Basic",
  metadata: {
    "mapbox:autocomposite": false,
    "mapbox:type": "template",
    "maputnik:renderer": "mbgljs",
    "openmaptiles:version": "3.x",
    "openmaptiles:mapbox:owner": "openmaptiles",
    "openmaptiles:mapbox:source:url": "mapbox://openmaptiles.4qljc88t",
  },
  sources: {
    openmaptiles: {
      type: "vector",
      url: "https://api.maptiler.com/tiles/v3-openmaptiles/tiles.json?key=" + import.meta.env.VITE_MAPTILER_TOKEN,
    },
  },
  sprite: "https://openmaptiles.github.io/maptiler-basic-gl-style/sprite",
  glyphs: "https://api.maptiler.com/fonts/{fontstack}/{range}.pbf?key=" + import.meta.env.VITE_MAPTILER_TOKEN,
  layers: [
    {
      id: "background",
      type: "background",
      paint: { "background-color": "hsl(47, 26%, 88%)" },
    },
    {
      id: "landuse-residential",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "landuse",
      filter: ["all", ["==", "$type", "Polygon"], ["in", "class", "residential", "suburb", "neighbourhood"]],
      layout: { visibility: "visible" },
      paint: { "fill-color": "hsl(47, 13%, 86%)", "fill-opacity": 0.7 },
    },
    {
      id: "landcover_grass",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "landcover",
      filter: ["==", "class", "grass"],
      paint: { "fill-color": "hsl(82, 46%, 72%)", "fill-opacity": 0.45 },
    },
    {
      id: "landcover_wood",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "landcover",
      filter: ["==", "class", "wood"],
      paint: {
        "fill-color": "hsl(82, 46%, 72%)",
        "fill-opacity": {
          base: 1,
          stops: [
            [8, 0.6],
            [22, 1],
          ],
        },
      },
    },
    {
      id: "water",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "water",
      filter: ["all", ["==", "$type", "Polygon"], ["!=", "intermittent", 1], ["!=", "brunnel", "tunnel"]],
      layout: { visibility: "visible" },
      paint: { "fill-color": "hsl(205, 56%, 73%)" },
    },
    {
      id: "water_intermittent",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "water",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "intermittent", 1]],
      layout: { visibility: "visible" },
      paint: { "fill-color": "hsl(205, 56%, 73%)", "fill-opacity": 0.7 },
    },
    {
      id: "landcover-ice-shelf",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "landcover",
      filter: ["==", "subclass", "ice_shelf"],
      layout: { visibility: "visible" },
      paint: { "fill-color": "hsl(47, 26%, 88%)", "fill-opacity": 0.8 },
    },
    {
      id: "landcover-glacier",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "landcover",
      filter: ["==", "subclass", "glacier"],
      layout: { visibility: "visible" },
      paint: {
        "fill-color": "hsl(47, 22%, 94%)",
        "fill-opacity": {
          base: 1,
          stops: [
            [0, 1],
            [8, 0.5],
          ],
        },
      },
    },
    {
      id: "landcover_sand",
      type: "fill",
      metadata: {},
      source: "openmaptiles",
      "source-layer": "landcover",
      filter: ["all", ["in", "class", "sand"]],
      paint: {
        "fill-antialias": false,
        "fill-color": "rgba(232, 214, 38, 1)",
        "fill-opacity": 0.3,
      },
    },
    {
      id: "landuse",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "landuse",
      filter: ["==", "class", "agriculture"],
      layout: { visibility: "visible" },
      paint: { "fill-color": "#eae0d0" },
    },
    {
      id: "landuse_overlay_national_park",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "landcover",
      filter: ["==", "class", "national_park"],
      paint: {
        "fill-color": "#E1EBB0",
        "fill-opacity": {
          base: 1,
          stops: [
            [5, 0],
            [9, 0.75],
          ],
        },
      },
    },
    {
      id: "waterway-tunnel",
      type: "line",
      source: "openmaptiles",
      "source-layer": "waterway",
      filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "tunnel"]],
      layout: { visibility: "visible" },
      paint: {
        "line-color": "hsl(205, 56%, 73%)",
        "line-dasharray": [3, 3],
        "line-gap-width": {
          stops: [
            [12, 0],
            [20, 6],
          ],
        },
        "line-opacity": 1,
        "line-width": {
          base: 1.4,
          stops: [
            [8, 1],
            [20, 2],
          ],
        },
      },
    },
    {
      id: "waterway",
      type: "line",
      source: "openmaptiles",
      "source-layer": "waterway",
      filter: ["all", ["==", "$type", "LineString"], ["!in", "brunnel", "tunnel", "bridge"], ["!=", "intermittent", 1]],
      layout: { visibility: "visible" },
      paint: {
        "line-color": "hsl(205, 56%, 73%)",
        "line-opacity": 1,
        "line-width": {
          base: 1.4,
          stops: [
            [8, 1],
            [20, 8],
          ],
        },
      },
    },
    {
      id: "waterway_intermittent",
      type: "line",
      source: "openmaptiles",
      "source-layer": "waterway",
      filter: ["all", ["==", "$type", "LineString"], ["!in", "brunnel", "tunnel", "bridge"], ["==", "intermittent", 1]],
      layout: { visibility: "visible" },
      paint: {
        "line-color": "hsl(205, 56%, 73%)",
        "line-dasharray": [2, 1],
        "line-opacity": 1,
        "line-width": {
          base: 1.4,
          stops: [
            [8, 1],
            [20, 8],
          ],
        },
      },
    },
    {
      id: "tunnel_railway_transit",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      minzoom: 0,
      filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "tunnel"], ["==", "class", "transit"]],
      layout: { "line-cap": "butt", "line-join": "miter" },
      paint: {
        "line-color": "hsl(34, 12%, 66%)",
        "line-dasharray": [3, 3],
        "line-opacity": {
          base: 1,
          stops: [
            [11, 0],
            [16, 1],
          ],
        },
      },
    },
    {
      id: "building",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "building",
      paint: {
        "fill-antialias": true,
        "fill-color": "rgba(222, 211, 190, 1)",
        "fill-opacity": {
          base: 1,
          stops: [
            [13, 0],
            [15, 1],
          ],
        },
        "fill-outline-color": {
          stops: [
            [15, "rgba(212, 177, 146, 0)"],
            [16, "rgba(212, 177, 146, 0.5)"],
          ],
        },
      },
    },
    {
      id: "housenumber",
      type: "symbol",
      source: "openmaptiles",
      "source-layer": "housenumber",
      minzoom: 17,
      filter: ["==", "$type", "Point"],
      layout: {
        "text-field": "{housenumber}",
        "text-font": ["Noto Sans Regular"],
        "text-size": 10,
      },
      paint: { "text-color": "rgba(212, 177, 146, 1)" },
    },
    {
      id: "road_area_pier",
      type: "fill",
      metadata: {},
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "class", "pier"]],
      layout: { visibility: "visible" },
      paint: { "fill-antialias": true, "fill-color": "hsl(47, 26%, 88%)" },
    },
    {
      id: "road_pier",
      type: "line",
      metadata: {},
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["in", "class", "pier"]],
      layout: { "line-cap": "round", "line-join": "round" },
      paint: {
        "line-color": "hsl(47, 26%, 88%)",
        "line-width": {
          base: 1.2,
          stops: [
            [15, 1],
            [17, 4],
          ],
        },
      },
    },
    {
      id: "road_bridge_area",
      type: "fill",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "Polygon"], ["in", "brunnel", "bridge"]],
      layout: {},
      paint: { "fill-color": "hsl(47, 26%, 88%)", "fill-opacity": 0.5 },
    },
    {
      id: "road_path",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["in", "class", "path", "track"]],
      layout: { "line-cap": "square", "line-join": "bevel" },
      paint: {
        "line-color": "hsl(0, 0%, 97%)",
        "line-dasharray": [1, 1],
        "line-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 10],
          ],
        },
      },
    },
    {
      id: "road_minor",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      minzoom: 13,
      filter: ["all", ["==", "$type", "LineString"], ["in", "class", "minor", "service"]],
      layout: { "line-cap": "round", "line-join": "round" },
      paint: {
        "line-color": "hsl(0, 0%, 97%)",
        "line-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
    },
    {
      id: "tunnel_minor",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "tunnel"], ["==", "class", "minor_road"]],
      layout: { "line-cap": "butt", "line-join": "miter" },
      paint: {
        "line-color": "#efefef",
        "line-dasharray": [0.36, 0.18],
        "line-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
    },
    {
      id: "tunnel_major",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "tunnel"], ["in", "class", "primary", "secondary", "tertiary", "trunk"]],
      layout: { "line-cap": "butt", "line-join": "miter" },
      paint: {
        "line-color": "#fff",
        "line-dasharray": [0.28, 0.14],
        "line-width": {
          base: 1.4,
          stops: [
            [6, 0.5],
            [20, 30],
          ],
        },
      },
    },
    {
      id: "aeroway-area",
      type: "fill",
      metadata: { "mapbox:group": "1444849345966.4436" },
      source: "openmaptiles",
      "source-layer": "aeroway",
      minzoom: 4,
      filter: ["all", ["==", "$type", "Polygon"], ["in", "class", "runway", "taxiway"]],
      layout: { visibility: "visible" },
      paint: {
        "fill-color": "rgba(255, 255, 255, 1)",
        "fill-opacity": {
          base: 1,
          stops: [
            [13, 0],
            [14, 1],
          ],
        },
      },
    },
    {
      id: "aeroway-taxiway",
      type: "line",
      metadata: { "mapbox:group": "1444849345966.4436" },
      source: "openmaptiles",
      "source-layer": "aeroway",
      minzoom: 12,
      filter: ["all", ["in", "class", "taxiway"], ["==", "$type", "LineString"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
        visibility: "visible",
      },
      paint: {
        "line-color": "rgba(255, 255, 255, 1)",
        "line-opacity": 1,
        "line-width": {
          base: 1.5,
          stops: [
            [12, 1],
            [17, 10],
          ],
        },
      },
    },
    {
      id: "aeroway-runway",
      type: "line",
      metadata: { "mapbox:group": "1444849345966.4436" },
      source: "openmaptiles",
      "source-layer": "aeroway",
      minzoom: 4,
      filter: ["all", ["in", "class", "runway"], ["==", "$type", "LineString"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
        visibility: "visible",
      },
      paint: {
        "line-color": "rgba(255, 255, 255, 1)",
        "line-opacity": 1,
        "line-width": {
          base: 1.5,
          stops: [
            [11, 4],
            [17, 50],
          ],
        },
      },
    },
    {
      id: "road_trunk_primary",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["in", "class", "trunk", "primary"]],
      layout: { "line-cap": "round", "line-join": "round" },
      paint: {
        "line-color": "#fff",
        "line-width": {
          base: 1.4,
          stops: [
            [6, 0.5],
            [20, 30],
          ],
        },
      },
    },
    {
      id: "road_secondary_tertiary",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["in", "class", "secondary", "tertiary"]],
      layout: { "line-cap": "round", "line-join": "round" },
      paint: {
        "line-color": "#fff",
        "line-width": {
          base: 1.4,
          stops: [
            [6, 0.5],
            [20, 20],
          ],
        },
      },
    },
    {
      id: "road_major_motorway",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["==", "class", "motorway"]],
      layout: { "line-cap": "round", "line-join": "round" },
      paint: {
        "line-color": "hsl(0, 0%, 100%)",
        "line-offset": 0,
        "line-width": {
          base: 1.4,
          stops: [
            [8, 1],
            [16, 10],
          ],
        },
      },
    },
    {
      id: "railway-transit",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "class", "transit"], ["!=", "brunnel", "tunnel"]],
      layout: { visibility: "visible" },
      paint: {
        "line-color": "hsl(34, 12%, 66%)",
        "line-opacity": {
          base: 1,
          stops: [
            [11, 0],
            [16, 1],
          ],
        },
      },
    },
    {
      id: "railway",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["==", "class", "rail"],
      layout: { visibility: "visible" },
      paint: {
        "line-color": "hsl(34, 12%, 66%)",
        "line-opacity": {
          base: 1,
          stops: [
            [11, 0],
            [16, 1],
          ],
        },
      },
    },
    {
      id: "waterway-bridge-case",
      type: "line",
      source: "openmaptiles",
      "source-layer": "waterway",
      filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"]],
      layout: { "line-cap": "butt", "line-join": "miter" },
      paint: {
        "line-color": "#bbbbbb",
        "line-gap-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
        "line-width": {
          base: 1.6,
          stops: [
            [12, 0.5],
            [20, 10],
          ],
        },
      },
    },
    {
      id: "waterway-bridge",
      type: "line",
      source: "openmaptiles",
      "source-layer": "waterway",
      filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"]],
      layout: { "line-cap": "round", "line-join": "round" },
      paint: {
        "line-color": "hsl(205, 56%, 73%)",
        "line-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
    },
    {
      id: "bridge_minor case",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"], ["==", "class", "minor_road"]],
      layout: { "line-cap": "butt", "line-join": "miter" },
      paint: {
        "line-color": "#dedede",
        "line-gap-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
        "line-width": {
          base: 1.6,
          stops: [
            [12, 0.5],
            [20, 10],
          ],
        },
      },
    },
    {
      id: "bridge_major case",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"], ["in", "class", "primary", "secondary", "tertiary", "trunk"]],
      layout: { "line-cap": "butt", "line-join": "miter" },
      paint: {
        "line-color": "#dedede",
        "line-gap-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
        "line-width": {
          base: 1.6,
          stops: [
            [12, 0.5],
            [20, 10],
          ],
        },
      },
    },
    {
      id: "bridge_minor",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"], ["==", "class", "minor_road"]],
      layout: { "line-cap": "round", "line-join": "round" },
      paint: {
        "line-color": "#efefef",
        "line-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
    },
    {
      id: "bridge_major",
      type: "line",
      source: "openmaptiles",
      "source-layer": "transportation",
      filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"], ["in", "class", "primary", "secondary", "tertiary", "trunk"]],
      layout: { "line-cap": "round", "line-join": "round" },
      paint: {
        "line-color": "#fff",
        "line-width": {
          base: 1.4,
          stops: [
            [6, 0.5],
            [20, 30],
          ],
        },
      },
    },
    {
      id: "admin_sub",
      type: "line",
      source: "openmaptiles",
      "source-layer": "boundary",
      filter: ["in", "admin_level", 4, 6, 8],
      layout: { visibility: "visible" },
      paint: { "line-color": "hsla(0, 0%, 60%, 0.5)", "line-dasharray": [2, 1] },
    },
    {
      id: "admin_country_z0-4",
      type: "line",
      source: "openmaptiles",
      "source-layer": "boundary",
      minzoom: 0,
      maxzoom: 5,
      filter: ["all", ["<=", "admin_level", 2], ["==", "$type", "LineString"], ["!has", "claimed_by"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
        visibility: "visible",
      },
      paint: {
        "line-color": "hsl(0, 0%, 60%)",
        "line-width": {
          base: 1.3,
          stops: [
            [3, 0.5],
            [22, 15],
          ],
        },
      },
    },
    {
      id: "admin_country_z5-",
      type: "line",
      source: "openmaptiles",
      "source-layer": "boundary",
      minzoom: 5,
      filter: ["all", ["<=", "admin_level", 2], ["==", "$type", "LineString"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
        visibility: "visible",
      },
      paint: {
        "line-color": "hsl(0, 0%, 60%)",
        "line-width": {
          base: 1.3,
          stops: [
            [3, 0.5],
            [22, 15],
          ],
        },
      },
    },
    {
      id: "poi_label",
      type: "symbol",
      source: "openmaptiles",
      "source-layer": "poi",
      minzoom: 14,
      filter: ["all", ["==", "$type", "Point"], ["==", "rank", 1]],
      layout: {
        "icon-size": 1,
        "text-anchor": "top",
        "text-field": "{name:latin}\n{name:nonlatin}",
        "text-font": ["Noto Sans Regular"],
        "text-max-width": 8,
        "text-offset": [0, 0.5],
        "text-size": 11,
        visibility: "visible",
      },
      paint: {
        "text-color": "#666",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-width": 1,
      },
    },
    {
      id: "airport-label",
      type: "symbol",
      source: "openmaptiles",
      "source-layer": "aerodrome_label",
      minzoom: 10,
      filter: ["all", ["has", "iata"]],
      layout: {
        "icon-size": 1,
        "text-anchor": "top",
        "text-field": "{name:latin}\n{name:nonlatin}",
        "text-font": ["Noto Sans Regular"],
        "text-max-width": 8,
        "text-offset": [0, 0.5],
        "text-size": 11,
        visibility: "visible",
      },
      paint: {
        "text-color": "#666",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-width": 1,
      },
    },
    {
      id: "road_major_label",
      type: "symbol",
      source: "openmaptiles",
      "source-layer": "transportation_name",
      minzoom: 13,
      filter: ["==", "$type", "LineString"],
      layout: {
        "symbol-placement": "line",
        "text-field": "{name:latin} {name:nonlatin}",
        "text-font": ["Noto Sans Regular"],
        "text-letter-spacing": 0.1,
        "text-rotation-alignment": "map",
        "text-size": {
          base: 1.4,
          stops: [
            [10, 8],
            [20, 14],
          ],
        },
        "text-transform": "uppercase",
        visibility: "visible",
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "hsl(0, 0%, 100%)",
        "text-halo-width": 2,
      },
    },
    {
      id: "place_label_other",
      type: "symbol",
      source: "openmaptiles",
      "source-layer": "place",
      minzoom: 8,
      filter: ["all", ["==", "$type", "Point"], ["!in", "class", "city", "state", "country", "continent"]],
      layout: {
        "text-anchor": "center",
        "text-field": "{name:latin}\n{name:nonlatin}",
        "text-font": ["Noto Sans Regular"],
        "text-max-width": 6,
        "text-size": {
          stops: [
            [6, 10],
            [12, 14],
          ],
        },
        visibility: "visible",
      },
      paint: {
        "text-color": "hsl(0, 0%, 25%)",
        "text-halo-blur": 0,
        "text-halo-color": "hsl(0, 0%, 100%)",
        "text-halo-width": 2,
      },
    },
    {
      id: "place_label_city",
      type: "symbol",
      source: "openmaptiles",
      "source-layer": "place",
      maxzoom: 16,
      filter: ["all", ["==", "$type", "Point"], ["==", "class", "city"]],
      layout: {
        "text-field": "{name:latin}\n{name:nonlatin}",
        "text-font": ["Noto Sans Regular"],
        "text-max-width": 10,
        "text-size": {
          stops: [
            [3, 12],
            [8, 16],
          ],
        },
      },
      paint: {
        "text-color": "hsl(0, 0%, 0%)",
        "text-halo-blur": 0,
        "text-halo-color": "hsla(0, 0%, 100%, 0.75)",
        "text-halo-width": 2,
      },
    },
    {
      id: "country_label-other",
      type: "symbol",
      source: "openmaptiles",
      "source-layer": "place",
      maxzoom: 12,
      filter: ["all", ["==", "$type", "Point"], ["==", "class", "country"], ["!has", "iso_a2"]],
      layout: {
        "text-field": "{name:latin}",
        "text-font": ["Noto Sans Regular"],
        "text-max-width": 10,
        "text-size": {
          stops: [
            [3, 12],
            [8, 22],
          ],
        },
        visibility: "visible",
      },
      paint: {
        "text-color": "hsl(0, 0%, 13%)",
        "text-halo-blur": 0,
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-width": 2,
      },
    },
    {
      id: "country_label",
      type: "symbol",
      source: "openmaptiles",
      "source-layer": "place",
      maxzoom: 12,
      filter: ["all", ["==", "$type", "Point"], ["==", "class", "country"], ["has", "iso_a2"]],
      layout: {
        "text-field": "{name:latin}",
        "text-font": ["Noto Sans Bold"],
        "text-max-width": 10,
        "text-size": {
          stops: [
            [3, 12],
            [8, 22],
          ],
        },
        visibility: "visible",
      },
      paint: {
        "text-color": "hsl(0, 0%, 13%)",
        "text-halo-blur": 0,
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-width": 2,
      },
    },
  ],
  id: "basic",
};

// rewrite Map with react-map-gl/maplibre
export default function Maplibre({ onMapLoad, onChangeMapBounds, listings = [] }) {
  const mapRef = useRef(null);

  return (
    <Map
      ref={mapRef}
      reuseMaps={true} // for performance
      mapStyle={style}
      initialViewState={{
        longitude: 4.895168,
        latitude: 52.370216,
        zoom: 6,
      }}
      maxZoom={20}
      minZoom={6}
      attributionControl={false}
      onLoad={() => {
        onMapLoad(mapRef.current);
      }}
      onMoveEnd={(e) => {
        if (e.originalEvent == undefined) return;
        // reload markers on move
        const bounds = mapRef.current.getBounds();
        onChangeMapBounds({
          north: bounds.getNorth(),
          south: bounds.getSouth(),
          east: bounds.getEast(),
          west: bounds.getWest(),
        });
      }}>
      <NavigationControl showCompass={false} position="top-right" />
      <Markers listings={listings} mapRef={mapRef} />
    </Map>
  );
}

const ZOOM_MARKER_THRESHOLD = 10;
const Markers = ({ listings }) => {
  const [popupOpen, setPopupOpen] = useState(null);
  const [zoom, setZoom] = useState(null); // 10 or 11
  const [markers, setMarkers] = useState([]);
  const markersRef = useRef({});
  const { current: map } = useMap();

  useEffect(() => {
    if (!map) return;

    const initialZoom = map.getZoom().toFixed(0);
    setZoom(initialZoom);

    map.on("zoom", handleZoom);

    return () => {
      map.off("zoom", handleZoom);
    };
  }, [map, zoom]);

  useEffect(() => {
    if (!listings || listings.length === 0) return;
    const newMarkersMap = {};
    const currentZoom = map.getZoom().toFixed(0) > ZOOM_MARKER_THRESHOLD ? ZOOM_MARKER_THRESHOLD : ZOOM_MARKER_THRESHOLD - 1;

    // Add or update markers
    let markersAdd = 0;
    let markersKeep = 0;
    listings.forEach((listing) => {
      const existingMarker = markersRef.current[listing._id];
      // Keep the marker if it's already on the map and the zoom level is the same (means threshold not crossed)
      if (existingMarker && existingMarker.zoom === currentZoom) {
        markersKeep++;
        newMarkersMap[listing._id] = existingMarker;
      } else {
        markersAdd++;
        const marker = (
          <Marker
            key={listing._id}
            longitude={listing.geo_location.coordinates[0]}
            latitude={listing.geo_location.coordinates[1]}
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              setPopupOpen(listing);
            }}>
            <ListingMarker zoom={zoom} price={listing.price} advertId={listing._id} />
          </Marker>
        );
        newMarkersMap[listing._id] = { marker, zoom: currentZoom };
      }
    });

    markersRef.current = newMarkersMap;
    setMarkers(Object.values(newMarkersMap).map((item) => item.marker));
  }, [listings, zoom]);

  const handleZoom = () => {
    const currentZoom = map.getZoom().toFixed(0);
    if (!zoom) {
      setZoom(currentZoom);
      return;
    }
    // setZoom if threshold crossed to avoid unnecessary re-renders
    if ((zoom <= ZOOM_MARKER_THRESHOLD && currentZoom > ZOOM_MARKER_THRESHOLD) || (zoom > ZOOM_MARKER_THRESHOLD && currentZoom <= ZOOM_MARKER_THRESHOLD)) setZoom(currentZoom);
  };

  return (
    <>
      {popupOpen && (
        <Popup
          key={popupOpen._id}
          longitude={popupOpen.geo_location.coordinates[0]}
          latitude={popupOpen.geo_location.coordinates[1]}
          onClose={() => setPopupOpen(null)}
          closeButton={true}
          offsetLeft={10}>
          <AdvertCardPopupMap advertId={popupOpen._id} />
        </Popup>
      )}
      {markers}
    </>
  );
};

const ListingMarker = ({ zoom, price, advertId }) => {
  price = price >= 1000 ? `${(price / 1000).toFixed(1)}K` : parseFloat(price).toFixed(0) + "€";

  if (zoom > ZOOM_MARKER_THRESHOLD) {
    return (
      <svg width="45" height="22" viewBox="0 0 45 22" xmlns="http://www.w3.org/2000/svg" className={`svg-marker-${advertId}`}>
        <style>
          {`
          .svg-marker-${advertId} {
            border-radius: 28px;
            color: #000000;
            fill: #FFFFFF;
            transition: scale 0.3s;
            box-shadow: 0 2px 4px rgba(0,0,0,0.18), 0px 2px 4px 0px rgba(0, 0, 0, 0.18), 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
          }
          .price-text {
            font-size: 12px;
            font-family: Outfit, sans-serif;
            line-height: 0;
            pointer-events: none;
          }
          .svg-marker-${advertId}:hover {
            fill: #1a2d53;
            color: #FFFFFF;
            scale: 1.2;
          }
        `}
        </style>
        <rect x="0" y="0" width="45" height="22" rx="11" ry="11" />
        <text className="price-text" fill="currentColor" fontSize="12px" x="22.5" y="11" textAnchor="middle" dominantBaseline="central">
          {price}
        </text>
      </svg>
    );
  } else {
    return (
      <svg width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
        <g fill="#1a2d53" stroke="#FFFFFF" strokeWidth="1" transform="scale(1)" transformOrigin="40">
          <circle fill="#1a2d53" stroke="#FFFFFF" r="5" cx="40" cy="40" />
        </g>
      </svg>
    );
  }
};
