import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const PhoneNumberInput = ({ phone, onChange }) => {
  const preferredCountries = [
    "nl",
    "it",
    "de",
    "fr",
    "es",
    "us",
    "be",
    "gr",
    "ro",
    "hu",
    "uk",
    "pt",
    "pl",
    "in",
    "bg",
    "cy",
    "tr",
    "ie",
    "lt",
    "lv",
    "sk",
    "fi",
    "au",
    "za",
    "lu",
    "se",
    "si",
    "ch",
    "ae",
    "at",
  ];

  return <PhoneInput defaultCountry="nl" preferredCountries={preferredCountries} value={phone} onChange={onChange} />;
};

export default PhoneNumberInput;
