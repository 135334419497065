import { Switch } from "@headlessui/react";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Toggle({ onChange, value }) {
  return (
    <Switch checked={value} onChange={(e) => onChange(e)} className={`group relative inline-flex h-5 w-10 flex-shrink-0 items-center justify-center rounded-full `}>
      <span
        aria-hidden="true"
        className={classNames(value ? "bg-primary" : "bg-secondary-200", "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out")}
      />
      <span
        aria-hidden="true"
        className={classNames(
          value ? "translate-x-5" : "translate-x-0",
          "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-primary bg-white ring-0 transition-transform duration-200 ease-in-out",
        )}
      />
    </Switch>
  );
}
