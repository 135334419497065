import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, Navigate, Outlet, Route, Routes, useLocation, useNavigationType } from "react-router-dom";

import { ENVIRONMENT, SENTRY_URL } from "./config";
import { setUser } from "./redux/auth/actions";
import Crisp from "./services/crisp";
import Gtm from "./services/gtm";
import Hotjar from "./services/hotjar";
import { Mixpanel } from "./services/mixpanel";

import Loader from "@/components/Loader";
import Navbar from "@/components/navbar/Navbar";
import api from "@/services/api";
import useDevice from "./hooks/useDevice";
import PublicLayout from "./layouts/PublicLayout";
import Account from "./scenes/account";
import Auth from "./scenes/auth";
import Favorites from "./scenes/favorites";
import HousingMatches from "./scenes/housingMatches";
import SearchProfile from "./scenes/savedSearches";
import Checkout from "./scenes/upgrade/checkout";
import Upgrade from "./scenes/upgrade/pricing";

if (SENTRY_URL && ENVIRONMENT === "production") {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: "app",
    // ignoreErrors: ["TypeError: Failed to fetch", "TypeError: Load failed", "TypeError: NetworkError when attempting to fetch resource."],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.8,
  });
}

const AuthLayout = () => (
  <div className="flex min-h-screen w-full flex-col bg-gray-50">
    <div className="flex-1">
      <Outlet />
    </div>
  </div>
);

const ProtectedLayout = () => {
  const { user } = useSelector((state) => state.Auth);
  const [loading, setLoading] = useState(user ? false : true);
  const dispatch = useDispatch();
  // token to login as
  const token = new URLSearchParams(window.location.search).get("token");
  const userId = new URLSearchParams(window.location.search).get("user_id");
  const clientReferenceIdPromoteKit = new URLSearchParams(window.location.search).get("client_reference_id");

  useEffect(() => {
    async function fetchData() {
      try {
        if (token) {
          api.setToken(token);
          const res = await api.get("/user/signin_token_login_as");
          setLoading(false);
          if (res.ok) {
            dispatch(setUser(res.data.user));
            Mixpanel.identify(res.data.user._id);
            Mixpanel.people.set({ ...res.data.user, $email: res.data.user.email, $name: res.data.user.firstname + " " + res.data.user.lastname });
            Mixpanel.track("SUCCESSFULLY_LOADED_LOGIN_AS");
          } else {
            dispatch(setUser(null));
          }
        } else if (userId) {
          const res = await api.post("/user/login-with-id/" + userId, {
            clientReferenceIdPromoteKit: clientReferenceIdPromoteKit,
          });
          setLoading(false);
          if (res.ok) {
            dispatch(setUser(res.data.user));
            Mixpanel.identify(res.data.user._id);
            Mixpanel.people.set({ ...res.data.user, $email: res.data.user.email, $name: res.data.user.firstname + " " + res.data.user.lastname });
            Mixpanel.track("SUCCESSFULLY_LOADED_LOGIN_WITH_ID");
          } else {
            dispatch(setUser(null));
          }
        } else {
          const res = await api.get("/user/signin_token");
          setLoading(false);
          if (res.ok) {
            dispatch(setUser(res.data.user));
            Mixpanel.identify(res.data.user._id);
            Mixpanel.people.set({ ...res.data.user, $email: res.data.user.email, $name: res.data.user.firstname + " " + res.data.user.lastname });
            Mixpanel.track("SUCCESSFULLY_LOADED");
          } else {
            dispatch(setUser(null));
          }
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) return <Loader />;
  if (!user) return <Navigate to="/" />;

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-1 flex-col">
        <Navbar />
        <div className="flex min-h-[calc(100vh-var(--header-height)-var(--bottom-bar-height))] w-full flex-col">
          {/* padding for menu mobile */}
          <div className="flex-1 md:pb-0 pb-[var(--bottom-bar-height)]">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const CrispControl = () => {
  const { pathname } = useLocation();
  const device = useDevice();

  useEffect(() => {
    const crisp = window.$crisp;
    // we need to check if crisp is available
    if (crisp) {
      // hide chat on mobile when user is on homepage
      if (location.pathname === "/" && device === "mobile") crisp.push(["do", "chat:hide"]);
      else crisp.push(["do", "chat:show"]);
    }
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <CrispControl />
        <Hotjar />
        <Crisp />
        <Gtm />
        <SentryRoutes>
          <Route element={<AuthLayout />}>
            <Route path="/auth/*" element={<Auth />} />
          </Route>
          <Route element={<PublicLayout />}>
            <Route path="/upgrade" element={<Upgrade />} />
            <Route path="/checkout/:planName" element={<Checkout />} />
            <Route path="/rent/*" element={<HousingMatches />} />
            <Route path="/" element={<HousingMatches />} />
          </Route>
          <Route element={<ProtectedLayout />}>
            <Route path="/search-profile" element={<SearchProfile />} />
            <Route path="/account" element={<Account />} />
            <Route path="/favorites" element={<Favorites />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </SentryRoutes>
      </BrowserRouter>
    </>
  );
};

export default App;
