import { useEffect, useRef, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";

const ActionsMenu = ({ actions, Icon = (props) => <FaEllipsisV {...props} size={18} className="text-primary cursor-pointer" /> }) => {
  const [open, setOpen] = useState(false);

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div ref={wrapperRef} className="relative">
      <div
        onClick={(e) => {
          // We do not want flickering hence we stop propagation
          e.stopPropagation();
          setOpen((open) => !open);
        }}>
        <Icon />
      </div>

      {open && (
        <div className="absolute py-1 shadow bg-white min-w-48 right-0 m-1 rounded flex flex-col z-10">
          {actions &&
            Array.isArray(actions) &&
            actions.length > 0 &&
            actions.map((action, n) => (
              <div key={n} onClick={action.callback} className="flex gap-2 items-center cursor-pointer px-3 py-2 hover:bg-primary-50">
                {action.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
export default ActionsMenu;
