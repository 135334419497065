import React from "react";

const Loader = ({ mt = "mt-20" }) => {
  return (
    <div className={`flex items-center justify-center ${mt}`}>
      <div className="border-[3px] border-l-primary animate-spin text-primary inline-block h-8 w-8 rounded-full" role="status">
        <span className="hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
