import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import { Mixpanel } from "../../services/mixpanel";

import api from "../../services/api";

const Forgot = () => {
  const [values, setValues] = useState({ email: "" });
  const [done, setDone] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await api.post("/user/forgot_password", values);
      toast.success("Password reset link sent to your email");
      setDone(true);
    } catch (e) {
      toast.error("Error", e.code);
    }
  };

  if (done) {
    return (
      // Auth Wrapper
      <div className="mt-20 flex items-center justify-center text-center h-full">
        <div className="authWrapper font-myfont">
          <div className="text-center text-[32px] font-semibold mb-[15px]">Reset Password</div>
          <div className="text-[16px] text-center mb-[30px] py-0	px-[30px] text-[#555]">
            Password recovery link has been sent to your email please check you inbox and follow the link to reset your password.
          </div>
        </div>
      </div>
    );
  }

  return (
    // Auth Wrapper
    <div className="mx-auto w-full h-screen lg:h-full lg:mt-28 lg:w-1/3 p-14 rounded-md bg-white font-myfont border border-gray-300">
      <div className="text-center text-3xl font-semibold text-primary">Reset Password</div>
      <div className="my-4" />
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <div className="flex flex-col-reverse">
              <input
                className="peer bg-transparent outline-0 block w-full p-2.5 rounded-sm border border-gray-300 text-gray-800 leading-tight focus:outline-none focus:border-primary focus:border"
                name="email"
                type="email"
                id="email"
                value={values.email}
                onChange={(e) => setValues({ ...values, email: e.target.value })}
              />
              <label className="peer-focus:text-primary" htmlFor="email">
                Email
              </label>
            </div>
          </div>
          <button className="btn-primary w-full" type="submit">
            Send link
          </button>
        </form>
        <div className="text-sm mt-4 text-center">
          Already have an account ?{" "}
          <Link to="/auth" className="text-primary hover:underline">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
