import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { navigation } from "@/constants/index";
import { Mixpanel } from "@/services/mixpanel";

const MobileNavigationControls = () => {
  const { user } = useSelector((state) => state.Auth);

  return (
    <>
      <div className="w-full fixed inset-x-0 bottom-0 flex justify-evenly bg-white border-t z-40 h-[--bottom-bar-height]">
        {navigation
          .filter((nav) => nav.mobileBottomBarDisplayIcon)
          .map((item, index) => (
            <NavLink
              key={item.name}
              to={item.href}
              exact={item.exact}
              onClick={() => {
                Mixpanel.track("sidebar_mobile_click_" + item.name.toLowerCase().replace(" ", "_"));
              }}
              className={({ isActive }) => {
                const classes = "group w-full flex flex-col gap-x-3 justify-center items-center text-center text-sm";
                const activeClasses = "text-secondary ";
                return isActive ? classes + " " + activeClasses : classes;
              }}>
              <div className="relative">
                {item.name == "Favorites" && user?.favorite_adverts?.length > 0 && (
                  <span className="absolute bottom-2 left-3 bg-secondary w-5 h-5 text-white leading-none text-[12px] rounded-full text-center py-[4px]">
                    {user.favorite_adverts.length}
                  </span>
                )}
                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
              </div>
              <div className="text-[0.625rem] leading-none">{item.name}</div>
            </NavLink>
          ))}
      </div>
    </>
  );
};

export default MobileNavigationControls;
