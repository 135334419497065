const rentalDurationOptions = [
  {
    label: "1 week",
    value: 1,
  },
  {
    label: "2 weeks",
    value: 2,
  },
  {
    label: "3 weeks",
    value: 3,
  },
  {
    label: "1 month",
    value: 4,
  },
  {
    label: "2 months",
    value: 8,
  },
  {
    label: "3 months",
    value: 12,
  },
  {
    label: "6 months",
    value: 24,
  },
  {
    label: "1 year",
    value: 52,
  },
  {
    label: "2 years",
    value: 104,
  },
];

export { rentalDurationOptions };
