import React from "react";

const Modal = ({ isOpen, children, onClose, className = "w-full md:w-2/3", innerClassName = "w-full max-h-screen" }) => {
  if (!isOpen) return null;

  return (
    <>
      <div className="hidden md:flex fixed flex-col items-center justify-center w-full h-full top-0 left-0 z-50">
        <div className="absolute inset-0 z-0 overflow-y-auto bg-black bg-opacity-30 backdrop-blur-sm" onClick={onClose} />
        <div className={`${className} md:px-4 absolute z-10`}>
          <div className={`${innerClassName} z-10 bg-white rounded-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-y-auto`}>{children}</div>
        </div>
      </div>
      <div className="flex md:hidden fixed flex-col items-center justify-center w-screen inset-x-0 inset-y-0 z-50">{children}</div>
    </>
  );
};
export default Modal;
