import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { plans } from "@/constants";

const Upgrade = () => {
  return (
    <div className="p-5 w-full md:w-1/2 lg:w-1/3 flex justify-center">
      <PricingTable />
    </div>
  );
};

const PricingTable = () => {
  const [selectedPlan, setSelectedPlan] = useState(plans.TWO_MONTHS);

  const navigate = useNavigate();

  const redirectToLink = () => {
    navigate(`/checkout/${selectedPlan.url}`);
  };

  return (
    <div className="w-full">
      {[plans.ONE_MONTH, plans.TWO_MONTHS, plans.THREE_MONTHS].map((plan) => (
        <div
          key={plan?.name}
          className={`p-5 cursor-pointer bg-white mt-4 rounded-lg shadow transition-colors ${selectedPlan?.name === plan?.name ? "border-2 border-primary-900" : ""}`}
          onClick={() => setSelectedPlan(plan)}>
          <label htmlFor={plan?.name} className="flex justify-between items-center gap-5 w-full cursor-pointer">
            <div className="flex items-center gap-4">
              <div className="flex justify-between w-6">
                <input type="radio" className="w-6 h-6 text-primary rounded-full cursor-pointer" name="pricing" id={plan.name} checked={selectedPlan.name === plan.name} />
              </div>

              <div>
                <div className="text-lg nowrap-text">{plan.name}</div>
                {plan.save && <span className="text-xs text-gray-600 bg-gray-200 px-2 py-1 rounded-full">{plan.save}</span>}
              </div>
            </div>

            <div>
              {plan.name === plans.TWO_MONTHS.name && <div className="text-xs text-white bg-red-500 px-2 py-1 rounded-full w-fit">Most popular</div>}
              <div className="text-lg">
                <span className="text-2xl font-bold">{plan.price}</span> / per month
              </div>
            </div>
          </label>
        </div>
      ))}
      <button className="w-full py-3 bg-red-500 text-white uppercase font-bold rounded-lg mt-6 hover:bg-red-600" onClick={redirectToLink}>
        Start receiving matches
      </button>
    </div>
  );
};

export default Upgrade;
