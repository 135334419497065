import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Forgot from "./forgot";
import Reset from "./reset";
import Signin from "./signin";
import Signup from "./signup";

const Auth = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/auth/signin" />} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/reset" element={<Reset />} />
    </Routes>
  );
};

export default Auth;
