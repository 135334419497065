import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Logo from "@/assets/logo_renthunter.svg";
import DesktopNavMenu from "@/components/navbar/DesktopNavMenu";
import useDevice from "@/hooks/useDevice";
import OnBoardControl from "@/scenes/onboarding";
import { Mixpanel } from "@/services/mixpanel";
import HamburgerMenu from "./HamburgerMenu";
import MobileNavigationControls from "./MobileNavigationControls";

const NavBar = () => {
  const { user } = useSelector((state) => state.Auth);
  const device = useDevice();
  const navigate = useNavigate();

  // I want to hide MobileNavigationControls when url is on checkout/* and upgrade/*
  const location = useLocation();
  const isCheckout = location.pathname.includes("checkout");
  const isUpgrade = location.pathname.includes("upgrade");

  return (
    <>
      <nav
        id="navbar"
        className={`${
          device == "desktop" ? "sticky inset-y-0" : "static"
        } flex flex-row justify-between items-center px-5 md:px-8 w-full z-50 ease-in-out duration-300 bg-white h-[--header-height] border-b border-gray-200`}>
        <div
          className="flex justify-center items-center cursor-pointer"
          onClick={() => {
            Mixpanel.track("sidebar_click_logo");
            navigate("/");
          }}>
          <img src={Logo} alt="logo" className="h-12 md:h-16" />
        </div>
        <div className="flex">
          {/* hide on checkout */}
          {!isCheckout && !isUpgrade && <OnBoardControl />}
          {!isCheckout && !isUpgrade && <>{device !== "mobile" ? <DesktopNavMenu /> : <HamburgerMenu />}</>}
        </div>
      </nav>
      {/* Controls */}
      {device === "mobile" && user && !isCheckout && !isUpgrade && <MobileNavigationControls />}
    </>
  );
};
export default NavBar;
