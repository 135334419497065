import mixpanel from "mixpanel-browser";
import { ENVIRONMENT } from "../config";

let env_check = ENVIRONMENT === "production";
if (env_check) mixpanel.init("2f3325e139b4cc3935a7175d5ff22536", { track_pageview: "full-url" });

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
