import * as turf from "@turf/turf";

const createGeoJSON = (data) => {
  if (data.length === 0) {
    return {
      type: "FeatureCollection",
      features: [],
    };
  }
  const features = data.map((item) => {
    const { geo_location, ...properties } = item;
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: geo_location.coordinates,
      },
      properties,
    };
  });

  return {
    type: "FeatureCollection",
    features,
  };
};

const fitBounds = (map, geojsonAdverts) => {
  if (geojsonAdverts.features.length === 0) return;
  map.fitBounds(turf.bbox(geojsonAdverts), {
    padding: 50,
  });
};

export { createGeoJSON, fitBounds };
