import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Mixpanel } from "../../services/mixpanel";

import api from "../../services/api";

const Reset = () => {
  const navigate = useNavigate();
  const search = new URLSearchParams(useLocation().search);
  const token = search.get("token");
  // use when create password first time
  const firstname = search.get("firstname");

  const [isTokenValid, setIsTokenValid] = useState(true);
  const [values, setValues] = useState({ password: "", password1: "" });

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (values.password !== values.password1) return toast.error("Passwords do not match");

      const res = await api.post("/user/forgot_password_reset", { password: values.password, token });
      if (!res.ok) throw res;
      toast.success("Password reset successfully");
      navigate("/");
    } catch (e) {
      switch (e?.code) {
        case "PASSWORD_TOKEN_EXPIRED_OR_INVALID":
          setIsTokenValid(false);
          return toast.error("Token expired or invalid");
        case "PASSWORD_NOT_VALIDATED":
          return toast.error("Password not validated");
        default:
          break;
      }
      toast.error(`Error\n${e?.code}`);
    }
  };

  return (
    <div className="flex items-center justify-center p-4 text-center h-full">
      <div className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
        {firstname ? (
          <>
            <div className="text-center text-[32px] font-semibold"> Hey {firstname}! </div>
            <div className="text-center text-xl mb-4"> Create a new password </div>
          </>
        ) : (
          <>
            <div className="text-center text-[32px] font-semibold mb-4"> Create a new password </div>
          </>
        )}
        {token && isTokenValid ? (
          <form onSubmit={handleSubmit}>
            <div className="border-[1px] border-gray-200 bg-gray-50 text-gray-500 p-2 rounded-md"> Format: minimum 6 characters, at least one letter </div>
            <div className="mb-[25px] mt-4">
              <div className="flex flex-col-reverse">
                <input
                  className={`peer border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2`}
                  name="password"
                  type="password"
                  id="password"
                  value={values.password}
                  onChange={(e) => setValues({ ...values, password: e.target.value })}
                />
                <label className="" htmlFor="password">
                  New Password
                </label>
              </div>
            </div>
            <div className="mb-[25px]">
              <div className="flex flex-col-reverse">
                <input
                  className={`peer border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2`}
                  name="password1"
                  type="password"
                  id="password1"
                  value={values.password1}
                  onChange={(e) => setValues({ ...values, password1: e.target.value })}
                />
                <label className="" htmlFor="password1">
                  Retype Password
                </label>
              </div>
            </div>
            <button className="mt-5 w-[220px] bg-primary rounded-[30px] m-auto block text-[16px] p-[8px] min-h-[42px] text-white" type="submit">
              Create
            </button>
          </form>
        ) : (
          <div className="text-[16px] text-center mb-[30px] py-0	px-[30px] text-[#555]">
            Your token is expired, please request a new one
            <br />
            <Link to="/auth/forgot" className="text-primary font-bold">
              Reset Password
            </Link>
            .
          </div>
        )}
      </div>
    </div>
  );
};

export default Reset;
