import { BsQuestionLg } from "react-icons/bs";
import { CiHeart } from "react-icons/ci";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { PiBellLight } from "react-icons/pi";
import { VscAccount } from "react-icons/vsc";

export const navigation = [
  { name: "Home", href: "/", exact: "true", icon: IoSearchOutline, desktopNavBarDisplayIcon: false, mobileBottomBarDisplayIcon: true, afterIcon: null },
  { name: "Favorites", href: "/favorites", exact: "true", icon: CiHeart, desktopNavBarDisplayIcon: true, mobileBottomBarDisplayIcon: true, afterIcon: null },
  { name: "Saved searches", href: "/search-profile", exact: "true", icon: PiBellLight, desktopNavBarDisplayIcon: true, mobileBottomBarDisplayIcon: true, afterIcon: null },
  {
    name: "FAQ",
    href: "https://renthunter.nl/faq",
    exact: "true",
    icon: BsQuestionLg,
    desktopNavBarDisplayIcon: false,
    mobileBottomBarDisplayIcon: false,
    afterIcon: FaExternalLinkAlt,
  },
  { name: "Account", href: "/account", exact: "true", icon: VscAccount, desktopNavBarDisplayIcon: false, mobileBottomBarDisplayIcon: true, afterIcon: null },
];

export const SORT_BY = [
  {
    id: "",
    value: "Newest first",
  },
  {
    id: "price-lowest",
    value: "Lowest price",
  },
  {
    id: "price-highest",
    value: "Highest price",
  },
];

// test mode
// export const plans = {
//   ONE_MONTH: {
//     name: "1 Month",
//     save: "",
//     price: "€19",
//     amount: 1900,
//     productId: "prod_QIb13yt4l5GI5H",
//     priceId: "price_1PRzp1K0veQKVONwGUgL1b1z",
//     url: "1-month",
//   },
//   TWO_MONTHS: {
//     name: "2 Months",
//     save: "Save 15%",
//     price: "€16",
//     amount: 3200,
//     productId: "prod_QIb1qPngj8d4gD",
//     priceId: "price_1PRzpHK0veQKVONwJi4ZWKuP",
//     url: "2-months",
//   },
//   THREE_MONTHS: {
//     name: "3 Months",
//     save: "Save 30%",
//     price: "€14",
//     amount: 4200,
//     productId: "prod_QIb1iobMhRDTuJ",
//     priceId: "price_1PRzpWK0veQKVONw9LLGnDmb",
//     url: "3-months",
//   },
// };

// live mode
export const plans = {
  ONE_MONTH: {
    name: "1-month plan",
    save: "",
    price: "€19",
    amount: 1900,
    productId: "prod_PnlO4MnHVgseig",
    priceId: "price_1Oy9ruK0veQKVONwucDgjDI7",
    url: "1-month",
  },
  TWO_MONTHS: {
    name: "2-month plan",
    save: "Save 15%",
    price: "€16",
    amount: 3200,
    productId: "prod_PjGqCQTak350LB",
    priceId: "price_1OtoJ8K0veQKVONwOxoXzfqt",
    url: "2-months",
  },
  THREE_MONTHS: {
    name: "3-month plan",
    save: "Save 30%",
    price: "€14",
    amount: 4200,
    productId: "prod_PjGsKcQRmd3IB9",
    priceId: "price_1OtoKIK0veQKVONw2bpM3rX1",
    url: "3-months",
  },
};
