import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import ImageSlider from "@/components/ImageSlider";
import useDevice from "@/hooks/useDevice";
import { setUser } from "../../redux/auth/actions";
import api from "../../services/api";
import { Mixpanel } from "../../services/mixpanel";
import { capitalizeFirstLetter, timeAgo } from "../../utils";
import { TRUSTED_SOURCES } from "../../utils/constants";

const AdvertCard = ({ advert, index, setAdvertQueryId = () => {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const device = useDevice();
  const { user } = useSelector((state) => state.Auth);

  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    if (user) setFavorites(user.favorite_adverts);
  }, [user]);

  const addFavorite = async (advert) => {
    if (!user) return navigate("auth/signin");
    try {
      const newFavorites = [
        ...favorites,
        {
          advert_id: advert._id,
        },
      ];
      const res = await api.put("/user", { favorite_adverts: newFavorites });
      if (!res.ok) throw new Error("Error");
      setFavorites(res.data.favorite_adverts);
      dispatch(setUser(res.data));
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const removeFavorite = async (advert) => {
    try {
      if (!window.confirm("Are you sure you want to remove this listing from your favorites?")) return;
      const newFavorites = favorites.filter((fav) => fav.advert_id !== advert._id);
      const res = await api.put("/user", { favorite_adverts: newFavorites });
      if (!res.ok) throw new Error("Error");
      setFavorites(res.data.favorite_adverts);
      dispatch(setUser(res.data));
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const renderAdvertInfos = (advert) => {
    const parts = [];

    // Add furnishing information, capitalized
    if (advert.furnishing) {
      parts.push(capitalizeFirstLetter(advert.converted_furnishing));
    }

    // Add room information based on the number of rooms
    if (advert.rooms) {
      parts.push(advert.rooms + (advert.rooms === 1 ? " room" : " rooms"));
    }

    // Add bedroom information based on the number of bedrooms
    if (advert.bedrooms) {
      parts.push(advert.bedrooms + (advert.bedrooms === 1 ? " bedroom" : " bedrooms"));
    }

    // Join all parts with " - " as separator
    return parts.join(" - ");
  };

  const advertImages = advert.images.length > 0 ? advert.images : ["https://rentsaver.s3.nl-ams.scw.cloud/app/propertyPlaceHolder.png"];
  const Node = device === "mobile" ? "div" : Link;

  return (
    <div
      key={index}
      onMouseEnter={() => {
        /**
         Advert hover effect explanations: 
         * marker z-index always between 1 and 2
         * tooltip background color changes on hover
         * 
         * Css hover defined always in map.css and updateMarkerSVG function
         */
        const advertId = advert._id;

        const svgMarker = document.querySelector(`.svg-marker-${advertId}`);
        if (!svgMarker) return;
        svgMarker.style.fill = "#1A2D53";
        svgMarker.style.color = "#FFFFFF";
        svgMarker.style.scale = 1.2;

        const parentSvgMarker = svgMarker.parentElement;
        parentSvgMarker.style.zIndex = 2;
      }}
      onMouseLeave={() => {
        const advertId = advert._id;

        const svgMarker = document.querySelector(`.svg-marker-${advertId}`);
        if (!svgMarker) return;
        svgMarker.style.fill = "#FFFFFF";
        svgMarker.style.color = "#000000";
        svgMarker.style.scale = 1;

        const parentSvgMarker = svgMarker.parentElement;
        parentSvgMarker.style.zIndex = 1;
      }}
      className="flex flex-col items-center justify-start relative">
      <div className="w-full rounded-xl overflow-hidden">
        <ImageSlider>
          {advertImages.map((imageUrl, n) => (
            <Node
              key={n}
              onClick={() => {
                if (device === "mobile") {
                  setAdvertQueryId(advert._id);
                }
              }}
              target={device === "mobile" ? "_self" : "_blank"}
              to={`/rent/${advert._id}`}
              className="w-full h-60 bg-center bg-cover cursor-pointer"
              style={{ backgroundImage: `url(${imageUrl})` }}></Node>
          ))}
        </ImageSlider>
      </div>
      <div className="absolute top-3 left-3">
        <div className="px-3 bg-secondary text-white w-fit rounded-lg text-sm">{timeAgo(advert.created_at)} ago</div>
      </div>
      <div className="flex flex-col w-full grow mt-2">
        <div className="flex justify-between items-center">
          <Node
            target={device === "mobile" ? "_self" : "_blank"}
            to={`/rent/${advert._id}`}
            className="text-primary font-bold"
            onClick={() => {
              Mixpanel.track("match_click", advert);
              if (device === "mobile") {
                setAdvertQueryId(advert._id);
              }
            }}>
            {capitalizeFirstLetter(advert.city)} - {capitalizeFirstLetter(advert.converted_type)} {advert.surface && <> - {advert.surface}m²</>}
          </Node>

          <div className="cursor-pointer">
            {favorites.find((fav) => fav.advert_id == advert._id) ? (
              <MdFavorite
                size={25}
                color="#DE5959"
                className="transition-transform duration-300 hover:scale-125"
                onClick={() => {
                  Mixpanel.track("match_remove_favorite", advert);
                  removeFavorite(advert);
                }}
              />
            ) : (
              <MdFavoriteBorder
                size={25}
                color="black"
                className="transition-transform duration-300 hover:scale-125"
                onClick={() => {
                  Mixpanel.track("match_add_favorite", advert);
                  addFavorite(advert);
                }}
              />
            )}
          </div>
        </div>
        <div className="flex justify-start items-center gap-2 text-sm">
          {capitalizeFirstLetter(advert.source)}
          {TRUSTED_SOURCES.includes(advert.source) && (
            <>
              <div>·</div>
              <div className="flex justify-start items-center gap-1">
                <VscWorkspaceTrusted size={15} color="green" /> <span> trusted advert</span>
              </div>
            </>
          )}
        </div>
        <div>
          <div className="text-sm">{renderAdvertInfos(advert)}</div>

          <div className="text-lg font-medium">{advert.price}€</div>
        </div>
      </div>
    </div>
  );
};

export default AdvertCard;
