import React from "react";

export default function useDevice(breakpointMobile = 768, breakPointLarge = 1024) {
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  React.useLayoutEffect(() => {
    function onResize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  if (innerWidth <= breakpointMobile) return "mobile";
  if (innerWidth <= breakPointLarge) return "tablet";
  else return "desktop";
}
