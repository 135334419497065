const SOURCES = [
  {
    name: "KAMERNET",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: true,
    readable_name: "Kamernet.nl",
  },
  {
    name: "FUNDA",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Funda.nl",
  },
  {
    name: "VBO",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Vbo.nl",
  },
  {
    name: "PARARIUS",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Pararius.nl",
  },
  {
    name: "RENTOLA",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: false,
    readable_name: "Rentola.nl",
  },
  {
    name: "HOUSINGANYWHERE",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: true,
    readable_name: "HousingAnywhere.com",
  },
  {
    name: "KAMER",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: true,
    readable_name: "Kamer.nl",
  },
  {
    name: "HOLLAND2STAY",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Holland2Stay.com",
  },
  {
    name: "VESTEDA",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Vesteda.com",
  },
  {
    name: "VVAAMSTERDAM",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Vvaamsterdam.nl",
  },
  {
    name: "THEHOMELIKE",
    trusted: true,
    has_registration: false,
    default_type: null,
    paid: false,
    readable_name: "TheHomelike.com",
  },
  {
    name: "123WONEN",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "123Wonen.nl",
  },
  {
    name: "IKWILHUREN",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "IkWilHuren.nu",
  },
  {
    name: "WOONNETRIJNMOND",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Woonnetrijnmond.nl",
  },
  {
    name: "VERHUURTBETER",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Verhuurtbeter.nl",
  },
  {
    name: "IAMEXPAT",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: false,
    readable_name: "IamExpat.nl",
  },
  {
    name: "ROOFZ",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Roofz.nl",
  },
  {
    name: "VANDERLINDEN",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "VanderLinden.nl",
  },
  {
    name: "NEDERWOON",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "NederWoon.nl",
  },
  {
    name: "AMSTERDAM.MIJNDAK",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Amsterdam.Mijndak.nl",
  },
  {
    name: "HOUSEHUNTING",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "HouseHunting.nl",
  },
  {
    name: "HUURWONINGEN",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: true,
    readable_name: "Huurwoningen.nl",
  },
  {
    name: "SCHEPVASTGOEDMANAGERS",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "SchepVastgoedmanagers.nl",
  },
  {
    name: "INTERHOUSE",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Interhouse.nl",
  },
  {
    name: "HOUSINGNET",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "HousingNet.nl",
  },
  {
    name: "AMSTERDAMWONEN",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "AmsterdamWonen.nl",
  },
  {
    name: "VBTVERHUURMAKELAARS",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "VbtVerhuurmakelaars.nl",
  },
  {
    name: "WOONZEKER",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Woonzeker.nl",
  },
  {
    name: "REBOHUURWONING",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "ReboHuurwoning.nl",
  },
  {
    name: "IK-ZOEK.DE-ALLIANTIE",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Ik-Zoek.De-Alliantie.nl",
  },
];

export const TRUSTED_SOURCES = SOURCES.filter((source) => source.trusted).map((source) => source.name);

export const PAID_SOURCES = SOURCES.filter((source) => source.paid).map((source) => source.name);

export const SOURCES_TO_READABLE = SOURCES.reduce((acc, source) => {
  acc[source.name] = source.readable_name;
  return acc;
}, {});
