import { useMemo, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ImageSlider.css";

const ImageSlider = ({ children }) => {
  const [active, setActive] = useState(0);
  const total = Array.isArray(children) ? children.length : 0;

  return (
    <Carousel
      showThumbs={false}
      showStatus={true}
      statusFormatter={(current, total) => `${current} / ${total}`}
      showIndicators={false}
      onChange={(index) => setActive(index)}
      renderArrowPrev={(clickHandler, hasPrev, label) => (
        <div className="carousel_arrow_wrapper left">
          <div onClick={clickHandler} className={`carousel_arrow ${hasPrev ? "" : "hidden"}`}>
            <IoIosArrowBack />
          </div>
        </div>
      )}
      renderArrowNext={(clickHandler, hasNext, label) => (
        <>
          <div className="carousel_arrow_wrapper right">
            <div onClick={clickHandler} className={`carousel_arrow ${hasNext ? "" : "hidden"}`}>
              <IoIosArrowBack style={{ transform: "rotateZ(180deg)" }} />
            </div>
          </div>
          <ImageSliderIndicator selected={active} total={total} />
        </>
      )}
      className="w-full">
      {children.map((child, index) => {
        if (index === active || index === active + 1 || index === active - 1) {
          return child;
        } else return null;
      })}
    </Carousel>
  );
};

export default ImageSlider;

// The below component is used to indicate the image position in the list (the tiny dots)
const ImageSliderIndicator = ({ selected, total }) => {
  // We want to indicate some form of continuity, so we need to know the first and last ball in the current 5 item list iteration of the indicators
  const [start, end] = useMemo(() => {
    const offset = 4;
    let start = Math.max(0, selected - 2); // Start 2 positions before the current iteration
    let end = start + offset; // End 4 positions after the start

    // Adjust the sequence if it goes beyond the total number of dots
    if (end >= total) {
      end = total - 1;
      start = Math.max(0, end - offset);
    }

    return [start, end];
  }, [selected, total]);

  const dotSize = 6.4;
  const dotSideMargin = 2.8;
  const indicatorSize = dotSize + 2 * dotSideMargin;

  const movementFactor = Math.max(Math.min(Math.max(selected - 2, 0), total - 5), 0);
  const translation = indicatorSize * movementFactor;

  const getClassName = (n) => {
    const isSmall = (n <= start && n !== 0) || (n >= end && n !== total - 1);
    const isInvisible = n < start || n > end;
    return `${isSmall ? "small" : ""} ${isInvisible ? "invisible" : ""}`;
  };

  return (
    <div className="carousel-indicator-wrapper" style={{}}>
      <ul style={{ width: indicatorSize * Math.min(total, 5) }} className="control-dots float">
        {Array(total)
          .fill(undefined)
          .map((_, n) => (
            <li
              style={{
                width: dotSize,
                height: dotSize,
                margin: `0 ${dotSideMargin}px`,
                transform: `translateX(-${translation}px)`,
              }}
              key={n}
              className={`dot ${selected === n ? "selected" : ""} ${getClassName(n)} ${total === 1 ? "invisible" : ""}`}>
              <span className="dot_inner"></span>
            </li>
          ))}
      </ul>
    </div>
  );
};
