import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { VscWorkspaceTrusted } from "react-icons/vsc";

import ImageSlider from "@/components/ImageSlider";
import Loader from "@/components/Loader";
import useDevice from "@/hooks/useDevice";
import { setUser } from "../../../redux/auth/actions";
import api from "../../../services/api";
import { Mixpanel } from "../../../services/mixpanel";
import { capitalizeFirstLetter, timeAgo } from "../../../utils";
import { TRUSTED_SOURCES } from "../../../utils/constants";

const AdvertCardPopupMap = ({ advertId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const device = useDevice();
  const { user } = useSelector((state) => state.Auth);
  const [advert, setAdvert] = useState(null);
  const [favorites, setFavorites] = useState([]);

  const fetchAdvert = async () => {
    try {
      const res = await api.get(`/advert/${advertId}`);
      if (!res.ok) throw new Error("Error");
      setAdvert(res.data);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  useEffect(() => {
    fetchAdvert();

    if (user) setFavorites(user.favorite_adverts);
  }, []);

  const addFavorite = async (advert) => {
    if (!user) return navigate("auth/signin");
    try {
      const newFavorites = [
        ...favorites,
        {
          advert_id: advert._id,
        },
      ];
      const res = await api.put("/user", { favorite_adverts: newFavorites });
      if (!res.ok) throw new Error("Error");
      setFavorites(res.data.favorite_adverts);
      dispatch(setUser(res.data));
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const removeFavorite = async (advert) => {
    try {
      if (!window.confirm("Are you sure you want to remove this listing from your favorites?")) return;
      const newFavorites = favorites.filter((fav) => fav.advert_id !== advert._id);
      const res = await api.put("/user", { favorite_adverts: newFavorites });
      if (!res.ok) throw new Error("Error");
      setFavorites(res.data.favorite_adverts);
      dispatch(setUser(res.data));
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const renderAdvertInfos = (advert) => {
    const parts = [];

    // Add furnishing information, capitalized
    if (advert.furnishing) {
      parts.push(capitalizeFirstLetter(advert.converted_furnishing));
    }

    // Add room information based on the number of rooms
    if (advert.rooms) {
      parts.push(advert.rooms + (advert.rooms === 1 ? " room" : " rooms"));
    }

    // Add bedroom information based on the number of bedrooms
    if (advert.bedrooms) {
      parts.push(advert.bedrooms + (advert.bedrooms === 1 ? " bedroom" : " bedrooms"));
    }

    // Join all parts with " - " as separator
    return parts.join(" - ");
  };

  const setAdvertQueryId = (advertQueryId) => {
    const params = new URLSearchParams(searchParams);
    if (advertQueryId) {
      params.set("advertId", `${advertQueryId}`);
    } else {
      params.delete("advertId");
    }
    setSearchParams(params);
  };

  if (!advert)
    return (
      // need to have weight & height defined for the auto anchor on the maplibre popup, otherwise the popup is often cut off (because no defined width & height at render)
      <div className="bg-white h-72 w-56 flex justify-center items-center">
        <Loader mt="mt-0" />
      </div>
    );

  const advertImages = advert.images.length > 0 ? advert.images : ["https://rentsaver.s3.nl-ams.scw.cloud/app/propertyPlaceHolder.png"];
  const Node = device === "mobile" ? "div" : Link;

  return (
    <div className="bg-white w-56 max-h-72 shadow flex flex-col items-center justify-start overflow-hidden z-10">
      <ImageSlider>
        {advertImages.map((imageUrl, n) => (
          <Node
            onClick={() => {
              Mixpanel.track("match_click", advert);
              if (device === "mobile") {
                setAdvertQueryId(advert._id);
              }
            }}
            to={`/rent/${advert._id}`}
            target={device === "mobile" ? "_self" : "_blank"}
            key={n}
            className="w-full h-40 bg-center bg-cover cursor-pointer"
            style={{ backgroundImage: `url(${imageUrl})` }}></Node>
        ))}
      </ImageSlider>
      <div className="flex flex-col justify-between p-3 w-full grow">
        <div>
          <Node
            to={`/rent/${advert._id}`}
            target={device === "mobile" ? "_self" : "_blank"}
            className="text-base leading-none text-primary font-bold cursor-pointer"
            onClick={() => {
              Mixpanel.track("match_click", advert);
              if (device === "mobile") {
                setAdvertQueryId(advert._id);
              }
            }}>
            {capitalizeFirstLetter(advert.city)} - {capitalizeFirstLetter(advert.converted_type)} {advert.surface && <> - {advert.surface}m²</>}
          </Node>
          <div className="flex justify-start items-center gap-2 text-xs">
            {capitalizeFirstLetter(advert.source)}
            {TRUSTED_SOURCES.includes(advert.source) && (
              <>
                <div>·</div>
                <div className="flex justify-start items-center gap-1">
                  <VscWorkspaceTrusted size={15} color="green" /> <span> trusted advert</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <div className="text-xs">{renderAdvertInfos(advert)}</div>

          <div className="flex justify-between items-center">
            <div className="text-lg font-medium">{advert.price}€</div>
            <div className="cursor-pointer">
              {favorites.find((fav) => fav.advert_id == advert._id) ? (
                <MdFavorite
                  size={25}
                  color="#DE5959"
                  className="transition-transform duration-300 hover:scale-125"
                  onClick={() => {
                    Mixpanel.track("match_remove_favorite", advert);
                    removeFavorite(advert);
                  }}
                />
              ) : (
                <MdFavoriteBorder
                  size={25}
                  color="black"
                  className="transition-transform duration-300 hover:scale-125"
                  onClick={() => {
                    Mixpanel.track("match_add_favorite", advert);
                    addFavorite(advert);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertCardPopupMap;
